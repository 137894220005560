


























































































































































































































































    import {
        Vue, Component, PropSync, Watch
    } from 'vue-property-decorator'
    import {State} from 'vuex-class'
    import api from '~/api'
    import SelectVehicleTypeSteps from '~/components/common-select-vehicle-by-steps.vue'
    import ImgLazyLoad from '~/components/img-lazy-load.vue'
    import utils from '~/utils'
    import CommonIndexBar from '~/components/common-index-bar.vue'
    import $config from '~/config'

    const fn = utils.debounce(function () {
        this.loadingStatus = false
        api.get_multiField({
            keyword: this.productKeywordsInput,
        }).then(res => {
            this.historyList = (res && res.data && res.data.map(list => list.fieldHighLight)) || []
            this.historyListRaw = (res && res.data && res.data.map(list => list.field)) || []
        }).finally(()=>{
            this.loadingStatus = true
        })
    }, 1000)
    // get_multiField
    // 定义联想框模型类
    class AutoCompleteModel {
    inputValue: string = ''                     // 输入框内的值(包括手动输入和选中操作的赋值)

    selectedRecommendationOption: any = {}      // 选中的联想项

    queryRecommendation: Function = () => {}    // 远程获取联想数据

    historyList: any[] = []

    historyListRaw: any[] = []

    fourCategoryId:any =  null

    thirdCategoryId:any =  null

    brandId:any =  null

    constructor() {
        // 给类的方法绑定this, 否则用实例单独调用方法时会找不到this
        this.handleSelectRecommendation = this.handleSelectRecommendation.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleClear = this.handleClear.bind(this)
        return this
    }

    handleSelectRecommendation(option) {        
        this.inputValue = option.brandName || option.categoryName || option.vehicleSerialName
        this.selectedRecommendationOption = option
        if(option.categoryName){
            if(option.fourCategoryId || option.fourthCategoryId){
                this.fourCategoryId = option.fourCategoryId || option.fourthCategoryId
            }
            if(option.thirdCategoryId){
                this.thirdCategoryId = option.thirdCategoryId
            }
        }
        if(option.brandName){
            this.brandId = option.brandId
        }
    }

    handleBlur() {
        setTimeout(() => {
            if (JSON.stringify(this.selectedRecommendationOption) == '{}') {
                this.inputValue = ''
            }
        }, 500)
    }


    handleClear() {
        this.inputValue = ''
        this.selectedRecommendationOption = {}
    }
    }

@Component({
    components: {
        SelectVehicleTypeSteps,
        ImgLazyLoad,
        CommonIndexBar
    },
})
    export default class SearchProductsBox extends Vue {
        @PropSync('footer-visible', {required: true}) footerBtnVisible!: boolean

        @State('openSearchKeywords') openSearchKeywords

        @State('oePartsBrand') oePartsBrand

        @State('countryId') countryId

        @State('langCode') langCode

        get imgDomainName() {
          return $config.imgDomainName
        }


        $refs!: {
            selectVehicleTypeSteps: any;
            productKeywordsInput: any;
        }

        productKeywordsInput: string = ''

        historyData: any[] = []

        isShowSelectVehicleTypeSteps: boolean = false

        brandAutoCompleteModel: AutoCompleteModel = new AutoCompleteModel()

        categoryAutoCompleteModel: AutoCompleteModel = new AutoCompleteModel()

        vehicleTypeAutoCompleteModel: AutoCompleteModel = new AutoCompleteModel()

        queryVehicleTypeNodeList: any[] = new Array(4)       // 路由上的query参数，用于搜索结果页车型四级联动框的初始值赋值

        tempQueryVehicleTypeNodeList: any[] = new Array(4)   // 点击more specific按钮后暂存queryVehicleTypeNodeList参数,防止进入step后没有选择back后数据清空

        historyList: any[] = []

        brandRecommendList: any[] = []

        categoryRecommendList: any[] = []

        vehicleTypeRecommendList: any[] = []

        brandList: any[] = []

        standardIdList: any[] = []

        thirdCategoryIdList: any[] = []

        indexListBrandIndex: any[] = []

        indexListBrand: any[] = []

        indexListCategoryIndex: any[] = []

        indexListCategory: any[] = []

        indexListVehicleIndex: any[] = []

        indexListVehicle: any[] = []

        isShowCategoryIndex: boolean = false

        isShowBrandIndex: boolean = false

        isShowVehicleIndex: boolean = false

        searchBanner: string = ''

        categoryList: any = []

        loadingStatus: any = false

        init() {
            this.getSearchHistory()
            this.getCommonSearchKey()
            this.getBrandIndex()
            this.getCategoryIndex()
            api.get_vehicle_list().then((res)=>{
                this.indexListVehicle = res.data.items
                this.indexListVehicle.map((vehicle)=>{
                    this.indexListVehicleIndex.push(vehicle.letter)
                })
            })
            api.get_oe_parts_image().then((res)=>{
                this.searchBanner = res.data.bannerUrl
            })
            if(this.openSearchKeywords){
                this.brandAutoCompleteModel.handleSelectRecommendation(this.oePartsBrand)
            }
            this.brandAutoCompleteModel.queryRecommendation = this.queryBrandSearchRecommendation
            this.categoryAutoCompleteModel.queryRecommendation = this.queryCategorySearchRecommendation
            this.vehicleTypeAutoCompleteModel.queryRecommendation = this.queryVehicleTypeRecommendation
        }

        getBrandIndex(){
            let param = {}
            if(this.categoryAutoCompleteModel.selectedRecommendationOption.fourthCategoryId){
                param.fourthCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.fourthCategoryId
            }
            if(this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId){
                param.thirdCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId
            }
            api.get_brand_list(param).then((res)=>{
                this.indexListBrand = res.data.items
                this.indexListBrandIndex = []
                this.indexListBrand.map((brand)=>{
                    this.indexListBrandIndex.push(brand.letter)
                })
            })
        }

        getCategoryIndex(){
            let param = {}
            if(this.brandAutoCompleteModel.selectedRecommendationOption.brandId){
                param.brandId = this.brandAutoCompleteModel.selectedRecommendationOption.brandId
            }
            api.get_category_list(param).then((res)=>{
                this.indexListCategory = res.data.items
                this.indexListCategoryIndex = []
                this.indexListCategory.map((category)=>{
                    this.indexListCategoryIndex.push(category.letter)
                })
            })
        }


        queryBrandSearchRecommendation(queryString: string, callback) {
            if (!queryString || !queryString.trim()) {
                callback([])
                return
            }
            let params: any = {
                brandName: queryString.trim(),
            }
            let thirdCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId
            if (thirdCategoryId) {
                params = { ...params, thirdCategoryId }
            }
            let fourthCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.fourthCategoryId
            if (this.categoryAutoCompleteModel.selectedRecommendationOption.fourthCategoryId) {
                params = { ...params, fourthCategoryId }
            }
            api.get_vehicle_search_brandName_or_categoryName(params).then(res => {
                this.isShowBrandIndex = false
                let data = (res && res.data) || {}
                callback(data.brandSearchParamList || [])
            })
        }

        queryCategorySearchRecommendation(queryString: string, callback) {
            if (!queryString || !queryString.trim()) {
                callback([])
                return
            }
            let params: any = {
                thirdCategoryName: queryString.trim(),
            }
            let brandId = this.brandAutoCompleteModel.selectedRecommendationOption.brandId
            if (brandId) {
                params = { ...params, brandId }
            }
            this.loadingStatus = false
            api.get_vehicle_search_brandName_or_categoryName(params).then(res => {
                this.isShowCategoryIndex = false
                let data = (res && res.data) || {}
                this.categoryList = data.categorySearchParamList
                callback(data.categorySearchParamList || [])
            }).finally(()=>{
                this.loadingStatus = true
            })
        }

        queryVehicleTypeRecommendation(queryString: string, callback) {
            if (!queryString || !queryString.trim()) {
                callback([])
                return
            }
            api.get_vehicle_search_serial({
                vehicleSerialName: queryString.trim(),
            }).then(res => {
                this.isShowVehicleIndex = false
                let data = (res && res.data) || {}
                let suggestions = data.vehicleSerialBOList || []
                if (suggestions.length > 0) {
                    suggestions.push({ id: 'moreSpecificBtn' })
                }
                callback(suggestions)
            })
        }

        handleFocus(type){
            setTimeout(()=>{
                if(type === 'brand' && !this.brandAutoCompleteModel.inputValue && this.indexListBrand.length){
                    this.isShowCategoryIndex = false
                    this.isShowVehicleIndex = false
                    this.isShowBrandIndex = true
                }
                if(type === 'category' && !this.categoryAutoCompleteModel.inputValue && this.indexListCategory.length ){
                    this.isShowBrandIndex = false
                    this.isShowVehicleIndex = false
                    this.isShowCategoryIndex = true
                }
                if(type === 'vehicle' && !this.vehicleTypeAutoCompleteModel.inputValue){
                    this.isShowBrandIndex = false
                    this.isShowCategoryIndex = false
                    this.isShowVehicleIndex = true
                }
                if(type === 'category'){
                    let el:any = this.$refs.categoryListRef
                    el.style.display = 'block'
                }
            })

        }

        handleClick(item,type){
            if(type === 'brand'){
                this.brandAutoCompleteModel.handleSelectRecommendation(item)
            }
            if(type === 'category'){
                this.categoryAutoCompleteModel.handleSelectRecommendation(item)
            }
            if(type === 'vehicle'){
                this.vehicleTypeAutoCompleteModel.handleSelectRecommendation(item)
            }
        }        

        handleSelectVehicleTypeRecommendation(option) {
            if (option.id === 'moreSpecificBtn') {
                return
            }
            this.vehicleTypeAutoCompleteModel.handleSelectRecommendation(option)
            this.queryVehicleTypeNodeList[0] = option.vehicleBrandId
            this.queryVehicleTypeNodeList[1] = option.vehicleSerialId
        }

        getSearchHistory() {
            api.get_search_history({
                searchType: 3,
            }).then(res => {
                this.historyData = (res && res.data) || []
            })
        }

        getCommonSearchKey(){
            api.get_common_search_key({
            }).then(res => {
                this.brandRecommendList = (res && res.data && res.data.brandList) || []
                this.categoryRecommendList = (res && res.data && res.data.categoryList) || []
                this.vehicleTypeRecommendList = (res && res.data && res.data.vehicleTypeList) || []
            })       
        }

        showSelectVehicleTypeSteps() {
            this.isShowSelectVehicleTypeSteps = true
            this.footerBtnVisible = false
            this.$nextTick(() => {
                this.$refs.selectVehicleTypeSteps.getVehicleBrand()
            })
        }

        gotoSpecificVehicle() {
            api.get_vehicle_search_more({
                vehicleSerialName: this.vehicleTypeAutoCompleteModel.inputValue,
            }).then(res => {
                if (+res.code !== 200) {
                    return
                }
                let data = (res && res.data) || {}
                this.isShowSelectVehicleTypeSteps = true
                this.footerBtnVisible = false
                const {
                    vehicleBrandId = '',
                    vehicleSerialId = '',
                } = data
                this.tempQueryVehicleTypeNodeList[0] = vehicleBrandId || ''
                this.tempQueryVehicleTypeNodeList[1] = vehicleSerialId || ''
                this.$nextTick(() => {
                    this.$refs.selectVehicleTypeSteps.setCurLevelType(data)
                })
            })
        }

        resetFormInput() {
            this.productKeywordsInput = ''
            this.brandAutoCompleteModel.handleClear()
            this.categoryAutoCompleteModel.handleClear()
            this.vehicleTypeAutoCompleteModel.handleClear()
        }

        async executeSearch(value) {
            let keywords
            if (value !== undefined) {
                const msg = value.replace(/<\/?[^>]*>/g, '')
                keywords = msg.trim()
                if (!keywords) {
                    return
                }
            } else {
                keywords = this.productKeywordsInput.trim()
                if ((!keywords || keywords.length < 2) && !(!!this.brandAutoCompleteModel.inputValue || !!this.categoryAutoCompleteModel.inputValue || !!this.vehicleTypeAutoCompleteModel.inputValue)) {
                    this.$refs.productKeywordsInput.focus()
                    this.$message({
                        customClass: 'common-customized-tip-message',
                        showClose: false,
                        message: ( keywords && (1 < keywords.length < 2) ) ? this.$t('label_search_product_msg') : this.$t('label_search_product_msg2'),
                        type: 'warning',
                        offset: 155,
                    })
                    return
                }
            }
            utils.fbq('track', 'Search', {
                search_string: keywords,
            })
            let metaParams = {
                keywords,
            }
            let query: any = {
                searchResultInfo: JSON.stringify(metaParams),
                keywords,
                searchProduct: 1,
                timeStand: new Date(),
                vehicleModelId: (this.vehicleTypeAutoCompleteModel.selectedRecommendationOption && this.vehicleTypeAutoCompleteModel.selectedRecommendationOption.length > 0) ? this.selectedvehicleModelId : '',
            }
            query.isSearchProductEntry = true
            let {
                vehicleModelId = '',
                vehicleSerialId = '',
            } = this.vehicleTypeAutoCompleteModel.selectedRecommendationOption
            if (vehicleModelId) {   // select by steps或者进入more specific选择的结果是四级
                query = { ...query, vehicleModelId }
            }
            if (vehicleSerialId) {  // 直接选择联想出的车型是二级
                query = { ...query, vehicleSerialId }
            }
            if (this.brandAutoCompleteModel.selectedRecommendationOption.brandId) {
                query.brandId = this.brandAutoCompleteModel.selectedRecommendationOption.brandId
            }
            if (this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId) {
                query.thirdCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId
            }
            if (this.brandAutoCompleteModel.brandId) {
                query.brandId = this.brandAutoCompleteModel.brandId
            }

            if (this.categoryAutoCompleteModel.thirdCategoryId) {
                query.thirdCategoryId = this.categoryAutoCompleteModel.thirdCategoryId
            }
            if (this.categoryAutoCompleteModel.fourCategoryId) {
                query.fourCategoryId = this.categoryAutoCompleteModel.fourCategoryId
            }
            if (this.queryVehicleTypeNodeList[0]) {
                query.vehicleTypeNodeList = JSON.stringify(this.queryVehicleTypeNodeList)
            }

            this.$router.push(this.localePath({
                name: 'multiConditionSearchPage',
                query,
            }))
            this.$emit('update:visible', false)
        }

        async executeSearchForTest(value, flag?: any) {
            let keywords
            if (value !== undefined) {
                const msg = value.replace(/<\/?[^>]*>/g, '')
                keywords = msg.trim()
                if (!keywords) {
                    return
                }
            } else {
                keywords = this.productKeywordsInput.trim()
                if ((!keywords || keywords.length < 2) && !(!!this.brandAutoCompleteModel.inputValue || !!this.categoryAutoCompleteModel.inputValue || !!this.vehicleTypeAutoCompleteModel.inputValue)) {
                    this.$refs.productKeywordsInput.focus()
                    this.$message({
                        customClass: 'common-customized-tip-message',
                        showClose: false,
                        message: ( keywords && (1 < keywords.length < 2) ) ? this.$t('label_search_product_msg') : this.$t('label_search_product_msg2'),
                        type: 'warning',
                        offset: 155,
                    })
                    return
                }
            }
            utils.fbq('track', 'Search', {
                search_string: keywords,
            })
            let metaParams = {
                keywords,
            }
            let query: any = {
                searchResultInfo: JSON.stringify(metaParams),
                keywords,
                searchProduct: 1,
                timeStand: new Date(),
                vehicleModelId: (this.vehicleTypeAutoCompleteModel.selectedRecommendationOption && this.vehicleTypeAutoCompleteModel.selectedRecommendationOption.length > 0) ? this.selectedvehicleModelId : '',
            }
            query.isSearchProductEntry = true
            let {
                vehicleModelId = '',
                vehicleSerialId = '',
            } = this.vehicleTypeAutoCompleteModel.selectedRecommendationOption
            if (vehicleModelId) {   // select by steps或者进入more specific选择的结果是四级
                query = { ...query, vehicleModelId }
            }
            if (vehicleSerialId) {  // 直接选择联想出的车型是二级
                query = { ...query, vehicleSerialId }
            }
            if (this.brandAutoCompleteModel.selectedRecommendationOption.brandId) {
                query.brandId = this.brandAutoCompleteModel.selectedRecommendationOption.brandId
            }
            if (this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId) {
                query.thirdCategoryId = this.categoryAutoCompleteModel.selectedRecommendationOption.thirdCategoryId
            }
            if (this.brandAutoCompleteModel.brandId) {
                query.brandId = this.brandAutoCompleteModel.brandId
            }
            if (this.categoryAutoCompleteModel.thirdCategoryId) {
                query.thirdCategoryId = this.categoryAutoCompleteModel.thirdCategoryId
            }
            if (this.categoryAutoCompleteModel.fourCategoryId) {
                query.fourCategoryId = this.categoryAutoCompleteModel.fourCategoryId
            }
            if (this.queryVehicleTypeNodeList[0]) {
                query.vehicleTypeNodeList = JSON.stringify(this.queryVehicleTypeNodeList)
            }
            query.matchCarModel = true
            if (flag === '17965') {
                 query.test70589 = true
            }
            this.$router.push(this.localePath({
                name: 'multiConditionSearchPage',
                query,
            }))
            this.$emit('update:visible', false)
        }

        /**
         *
         * @param node {Object} 四级vehicleModel节点
         * @param selectedNodeList {Array} 选中完整节点链路
         */
        moreSpecificBtnCallback({ node }) {
            // 获得四级车型的id
            this.vehicleTypeAutoCompleteModel.inputValue = node.eggVehicleKeyDescription
            this.vehicleTypeAutoCompleteModel.selectedRecommendationOption = node
            this.queryVehicleTypeNodeList[0] = this.tempQueryVehicleTypeNodeList[0]
            this.queryVehicleTypeNodeList[1] = this.tempQueryVehicleTypeNodeList[1]
            // node.forEach(item => {
            //     switch (item.nodeType) {
            //         case 'brand':
            //             this.queryVehicleTypeNodeList[0] = item.id
            //             break
            //         case 'serial':
            //             this.queryVehicleTypeNodeList[1] = item.id
            //             break
            //         case 'yearCapacity':
            //             this.queryVehicleTypeNodeList[2] = item.id
            //             break
            //         case 'model':
            //             this.queryVehicleTypeNodeList[3] = item.id
            //             break
            //         default:
            //             break
            //     }
            // })
            this.isShowSelectVehicleTypeSteps = false
            this.footerBtnVisible = true
        }

        handleBack() {
            this.isShowSelectVehicleTypeSteps = false
            this.footerBtnVisible = true
        }

        input() {
            let el:any = this.$refs.historyDetail
            if (!this.productKeywordsInput.trim().length) {
                el.style.display = 'none'
                return
            }
            el.style.display = 'block'
            fn(this)
        }

        inputBlur() {
            let el:any = this.$refs.historyDetail
            setTimeout(() => {
                el.style.display = 'none'
            }, 300)
        }

        inputFocus() {
            this.input()
        }

        @Watch('brandAutoCompleteModel.inputValue')
        refreshCategory(value) {
            if(value == ''){    
                this.brandAutoCompleteModel.handleClear()
            }
            this.getCategoryIndex()
        }

        @Watch('categoryAutoCompleteModel.inputValue')
        refreshBrand(value) {
            if(value == ''){    
                this.categoryAutoCompleteModel.handleClear()
            }
            this.getBrandIndex()
            let el:any = this.$refs.categoryListRef
            if (!value) {
                el.style.display = 'none'
                return
            }
            el.style.display = 'block'
        }

        handleFeedback(){
            this.$store.commit('SET_FEEDBACK_STATUS', true)
            this.$store.commit('SET_FEEDBACK_CONTENT', {
                list: this.historyListRaw,
                searchText: this.productKeywordsInput.trim(),
                predictionsSource: 1,
            })
        }

        handleFeedbackCategory(){
            this.$store.commit('SET_FEEDBACK_STATUS', true)
            this.$store.commit('SET_FEEDBACK_CONTENT', {
                list: this.categoryList,
                searchText: this.categoryAutoCompleteModel.inputValue,
                predictionsSource: 2,
            })
        }

        handleCategoryListClick(item){
            this.categoryAutoCompleteModel.handleSelectRecommendation(item)
            this.categoryList = []
        }

        handleCategoryListBlur(){
            this.categoryAutoCompleteModel.handleBlur()
            setTimeout(()=>{
                let el:any = this.$refs.categoryListRef
                el.style.display = 'none'
            }, 200)
        }

        
    }
